<script lang="ts">
    import type { GetProductResBody } from "@edenflowers/server/dist/server/product/types";
    import { getContext } from "svelte";
    import { _ } from "svelte-intl";
    import { fullScreenOnMobile } from "../../../components/modalOptions";
    import ProductCard from "../../../components/ProductCard.svelte";
    import { addOrRemoveFromCart } from "../../../controllers/cart";
    import ExtrasModal from "./ExtrasModal.svelte";
    import { extrasStore } from "../../../stores";

    export let products: GetProductResBody = [];

    function handleSelect(id: string) {
        extrasStore.update((store) => {
            return addOrRemoveFromCart(store, id);
        });
    }

    const { open } = getContext("simple-modal");
    function showInfoModal(id: string) {
        open(
            ExtrasModal,
            { product: products.find((p) => p.id === id) },
            fullScreenOnMobile(window.innerWidth)
        );
    }
</script>

<h1>{$_("add_extra")}</h1>
<span class="text-sm">{$_("add_extra_description")}</span>

<div class="my-8">
    <script lang="ts">
    </script>
    <div class="grid grid-cols-3 gap-2">
        {#each products as p}
            <svelte:component
                this={ProductCard}
                on:add={() => handleSelect(p.id)}
                on:info={() => showInfoModal(p.id)}
                selected={!!$extrasStore.find((i) => i.productId === p.id)}
                {...p}
            />
        {/each}
    </div>
</div>
